import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const TextPost = ({ data, id }) => {
  const { date, title, content } = data;
  const { isAuthenticated } = useAuth();

  return (
    <div className="text-post" id={id}>
      <div className="text-upper">
        <h1 className="text-title">{title}</h1>
        <h2 className="text-date">{date}</h2>
      </div>
      <div className="text-lower">
        <p className="text-content">{content}</p>
      </div>
      {isAuthenticated && (
        <div className="edit-link">
          <Link to="/story/update" state={{ id, title, content }}>
            Edit
          </Link>
        </div>
      )}
    </div>
  );
};

export default TextPost;
