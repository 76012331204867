import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateStory } from '../../store/reducers/storySlice';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';

const StoryUpdate = (props) => {
  const location = useLocation();
  console.log("Location state:", location.state);

  // Extract values from location.state if they exist.
  const { id: passedId, title: passedTitle, content: passedContent } = location.state || {};

  // Use passed-in props if available, otherwise fallback to location state.
  const storyId = props.storyId || passedId;
  const initialTitle = props.initialTitle || passedTitle || '';
  const initialContent = props.initialContent || passedContent || '';

  // Log the derived values.
  useEffect(() => {
    console.log("StoryUpdate initialized with:", { storyId, initialTitle, initialContent });
  }, [storyId, initialTitle, initialContent]);

  const { isAuthenticated, token } = useAuth();
  const dispatch = useDispatch();

  const [title, setTitle] = useState(initialTitle);
  const [content, setContent] = useState(initialContent);
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    if (isAuthenticated && token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      console.log("Axios auth header set with token:", token);
    } else {
      delete axios.defaults.headers.common['Authorization'];
      console.log("Axios auth header removed, user not authenticated");
    }
  }, [isAuthenticated, token]);

  if (!isAuthenticated) {
    return <div>Please login</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting update with:", { storyId, title, content });
    try {
      const resultAction = await dispatch(updateStory({ id: storyId, title, content }));
      console.log("Dispatch result action:", resultAction);
      if (updateStory.fulfilled.match(resultAction)) {
        console.log("Update successful:", resultAction.payload);
        setStatusMessage('Story updated successfully.');
      } else {
        console.error("Update failed:", resultAction);
        setStatusMessage('Failed to update story.');
      }
    } catch (error) {
      console.error('Error updating story:', error);
      setStatusMessage('Failed to update story.');
    }
  };

  return (
    <div>
      <h2
      style={{
        color: 'white',
        marginRight: '10px'
      }}
      >Update Story</h2>
      <form onSubmit={handleSubmit}>
        <div
            style={{
            marginBottom: '10px'
            }}
        >
          <label htmlFor="title"
          style={{
            color: 'white',
            marginRight: '10px'
          }}
          >Title:</label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            style={{
                width: '200px',
                padding: '0.5rem',
                fontSize: '.8rem',
            }}
          />
        </div>
        <div>
          <label htmlFor="content"
          style={{
            color: 'white',
            marginRight: '10px',
            display: 'flex',
          }}
          >Content:</label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
            style={{
                width: '300px',
                padding: '0.5rem',
                fontSize: '.8rem',
                height: '400px'
            }}
          />
        </div>
        <button type="submit">Update Story</button>
      </form>
      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

export default StoryUpdate;
