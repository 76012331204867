import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// -------------------------------------
// Helper: Retrieve token (or modify to use your auth slice)
// -------------------------------------
const getToken = (getState) => {
  // Example: if stored in localStorage
  return localStorage.getItem("token");
};

// -------------------------------------
// Thunks
// -------------------------------------

// 1) Fetch all artifacts
export const fetchArtifacts = createAsyncThunk(
  "artifacts/fetchArtifacts",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        "https://octopus-house.herokuapp.com/api/artifacts"
      );
      return data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response?.data || e.message);
    }
  }
);

// 2) Fetch single artifact data
export const fetchArtifactData = createAsyncThunk(
  "artifacts/fetchArtifactData",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `https://octopus-house.herokuapp.com/api/artifacts/${id}`
      );
      return data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response?.data || e.message);
    }
  }
);

// ------------------------------------------------------------------------
// NEW Thunks: Create, Update, Delete (requires a valid token)
// ------------------------------------------------------------------------

// 3) Create Artifact
export const createArtifact = createAsyncThunk(
  "artifacts/createArtifact",
  async (newArtifact, { getState, rejectWithValue }) => {
    try {
      const token = getToken(getState);
      if (!token) {
        return rejectWithValue("No token found. User not logged in.");
      }
      const { data } = await axios.post(
        "https://octopus-house.herokuapp.com/api/artifacts",
        newArtifact,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data; // newly created artifact from DB
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data || e.message);
    }
  }
);

// 4) Update Artifact
export const updateArtifact = createAsyncThunk(
  "artifacts/updateArtifact",
  async ({ id, updatedInfo }, { getState, rejectWithValue }) => {
    try {
      const token = getToken(getState);
      if (!token) {
        return rejectWithValue("No token found. User not logged in.");
      }
      const { data } = await axios.put(
        `https://octopus-house.herokuapp.com/api/artifacts/${id}`,
        updatedInfo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data; // updated artifact from DB
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data || e.message);
    }
  }
);

// 5) Delete Artifact
export const deleteArtifact = createAsyncThunk(
  "artifacts/deleteArtifact",
  async (id, { getState, rejectWithValue }) => {
    try {
      const token = getToken(getState);
      if (!token) {
        return rejectWithValue("No token found. User not logged in.");
      }
      await axios.delete(`https://octopus-house.herokuapp.com/api/artifacts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Return the ID for filtering out of the store
      return id;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data || e.message);
    }
  }
);

// -------------------------------------
// Slice
// -------------------------------------
const artifactSlice = createSlice({
  name: "artifactList",
  initialState: {
    isInitialDataLoaded: false,
    artifacts: [],
    artifactData: [],
    status: "idle",
    error: null,
    errorMsg: null, // to store error messages
    filter: null,
  },
  reducers: {
    setInitialDataLoaded: (state, action) => {
      state.isInitialDataLoaded = action.payload;
    },
    getArtifactList: (state, action) => {
      state.artifactList = action.payload;
    },
    getArtifact: (state, action) => {
      state.artifactData = action.payload;
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // fetchArtifacts
      .addCase(fetchArtifacts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchArtifacts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.artifacts = action.payload;
      })
      .addCase(fetchArtifacts.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      })

      // fetchArtifactData
      .addCase(fetchArtifactData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchArtifactData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.artifactData = action.payload;
      })
      .addCase(fetchArtifactData.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      })

      // -----------------------------------------------------------
      // NEW - createArtifact
      .addCase(createArtifact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createArtifact.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add the new artifact to the local array
        state.artifacts.push(action.payload);
      })
      .addCase(createArtifact.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      })

      // NEW - updateArtifact
      .addCase(updateArtifact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateArtifact.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Find the artifact in array & replace
        const updated = action.payload;
        const index = state.artifacts.findIndex((a) => a.id === updated.id);
        if (index !== -1) {
          state.artifacts[index] = updated;
        }
      })
      .addCase(updateArtifact.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      })

      // NEW - deleteArtifact
      .addCase(deleteArtifact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteArtifact.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Remove from local array
        const deletedId = action.payload;
        state.artifacts = state.artifacts.filter(
          (artifact) => artifact.id !== deletedId
        );
      })
      .addCase(deleteArtifact.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      });
  },
});

export const artifactSliceReducer = artifactSlice.reducer;

// Export actions
export const {
  getArtifactList,
  getArtifact,
  setInitialDataLoaded,
  setErrorMsg,
} = artifactSlice.actions;

export default artifactSlice;
