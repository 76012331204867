import { useEffect, useState } from "react";
import "../../css/story.css";
import TextPost from "./TextPost";
import ImagePost from "./ImagePost";
import FinalPost from "./FinalPost";
import '../../css/contact.css';

const InnerStory = ({ data }) => {
  const [sortedStory, setSortedStory] = useState([]);

  useEffect(() => {
    if (data.story && data.story.length > 0) {
      let sortedArray = [...data.story];

      for (let i = 0; i < sortedArray.length - 1; i++) {
        for (let j = 0; j < sortedArray.length - 1 - i; j++) {
          if (sortedArray[j].postID > sortedArray[j + 1].postID) {
            [sortedArray[j], sortedArray[j + 1]] = [sortedArray[j + 1], sortedArray[j]];
          }
        }
      }

      setSortedStory(sortedArray);
    }
  }, [data.story]);

  return (
    <div className="story-inner-container">
      <div className="post-container"
      >
        {sortedStory.length > 0 ? (
          sortedStory.map((post) => {
            if (Array.isArray(post.images) && post.images.length > 0) {
              return <ImagePost key={post.postID} data={post} id={post.postID} />;
            } else {
              return <TextPost key={post.postID} data={post} id={post.postID} />;
            }
          })
        ) : (
          <p>No posts available</p>
        )}
        <FinalPost />
        <div className="contact-content">
            What should I do next? 
            E-mail me and let me know your thoughts!
        </div>
        <form method="POST" data-netlify="true" name="contact" className='contact-form'>
            <input
            type="hidden"
            name="form-name"
            value="contact"
            />
            <input
            className='name-input'
            name="name"
            type="text"
            placeholder="name"
            required
            />
            <input
            className='email-input'
            name="email"
            type="email"
            placeholder="email"
            required
            />
            <textarea
            className='message-input'
            name="message"
            placeholder="further information"
            required
            ></textarea>
            <div className='form-button-wrapper'>
            </div>
        <button 
        className="submit-button" 
        type="submit"
        style={{
            marginBottom: '25px',
            marginTop: '25px'
        }}
        >Send</button>
        </form>
      </div>
    </div>
  );
};

export default InnerStory;
