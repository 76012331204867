import { useEffect } from "react";
import { fetchArtistData } from "../../store/reducers/artistSlice";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "../../css/artist.css";
import { fetchArtifactArtist } from "../../store/reducers/artifactArtistSlice";
import { fetchArtifacts } from "../../store/reducers/artifactSlice";

const ArtistView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // Safely retrieve the nested properties:
  const artist = useSelector((state) => state.artist.artistData?.artistData);
  // or if you changed your slice to store them separately, do that instead

  const artifactArtist = useSelector(
    (state) => state.artifactArtist.artifactArtist || []
  );
  const artifacts = useSelector((state) => state.artifact.artifacts || []);

  useEffect(() => {
    const asyncFetchData = async () => {
      await dispatch(fetchArtistData(id));
      await dispatch(fetchArtifactArtist());
      await dispatch(fetchArtifacts());
    };
    asyncFetchData();
  }, [dispatch, id]);

  // If "artist" is still null or undefined, you can show a loading or fallback:
  if (!artist) {
    return <div>Loading or No Artist Found</div>;
  }

  let artistsArtifacts = [];
  let singleArtifact = [];
  let imagePath = null;

  if (artifactArtist.length > 0 && artist.artistID) {
    // Find cross references with the same artistID
    artistsArtifacts = artifactArtist.filter((artifact) =>
      artifact.artistID.includes(parseInt(artist.artistID))
    );
    // Then find the actual artifact objects in "artifacts"
    singleArtifact = artifacts.filter(
      (art) => art.artifactID === artistsArtifacts[0]?.artifactID
    );
    if (singleArtifact[0]) {
      imagePath = `${process.env.PUBLIC_URL}/images/${singleArtifact[0].images[0]}`;
    }
  }

  return (
    <div className="artist-page">
      {artist && imagePath ? (
        <div className="artist-wrapper">
          <img
            src={imagePath}
            alt="this is where the main image goes"
            className="artist-main-image"
          />
          <div className="artist-info-container">
            {artist.company && artist.name ? (
              <>
                <div className="single-artist-name">{artist.name}</div>
                <div className="single-artist-title">{artist.title}</div>
                <div className="artist-company-name">{artist.company}</div>
              </>
            ) : artist.name ? (
              <>
                <div className="single-artist-name">{artist.name}</div>
                <div className="single-artist-title">{artist.title}</div>
              </>
            ) : (
              <>
                <div className="solo-artist-name">{artist.company}</div>
                <div className="single-artist-title">{artist.title}</div>
              </>
            )}
            {artist.social.map((social, index) => (
              <div key={index}>
                <a href={social} target="_blank" rel="noreferrer" className="social">
                  {social}
                </a>
              </div>
            ))}
            <div className="line-div" />
            <div className="artist-blurb">{artist.blurb}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ArtistView;
