import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const ImagePost = ({ data, id }) => {
  const { date, title, content, images } = data;
  const { isAuthenticated } = useAuth();

  console.log(isAuthenticated)

  return (
    <div className="image-post" id={id}>
      <div className="image-upper">
        <h2 className="image-date">{date}</h2>
        <h1 className="image-title">{title}</h1>
      </div>
      {images.length === 1 ? (
        <div className="image-lower">
          <div className="post-image-container">
            <img src={images[0]} alt="artifactImage" className="post-image" />
            <div className="image-content">{content}</div>
          </div>
        </div>
      ) : (
        <div className="image-lower">
          <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            infiniteLoop={true}
            autoPlay={false}
            swipeable={false}
            emulateTouch={true}
            dynamicHeight={false}
            useKeyboardArrows={true}
            centerMode={true}
            centerSlidePercentage={100}
            className="artifact-carousel"
            renderArrowPrev={(clickHandler, hasPrev) => (
              <div onClick={clickHandler} className="carousel-arrow-story carousel-arrow-left bounce-left">
                <a className="fa fa-arrow-left fa-2x"></a>
              </div>
            )}
            renderArrowNext={(clickHandler, hasNext) => (
              <div onClick={clickHandler} className="carousel-arrow-story carousel-arrow-right bounce-right">
                <a className="fa fa-arrow-right fa-2x"></a>
              </div>
            )}
          >
            {images.map((image, index) => (
              <div key={index} className="inner-wrapper">
                <img src={image} alt="artifactImage" className="post-image" />
              </div>
            ))}
          </Carousel>
          <div className="image-content">{content}</div>
        </div>
      )}
      {isAuthenticated && (
        <div className="edit-link"
        style={
            {
                marginBottom: "50px"
            }
        }>
          <Link to="/story/update" state={{ id, title, content }}>
            Edit
          </Link>
        </div>
      )}
    </div>
  );
};

export default ImagePost;
