import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import '../../css/Home.css';

import Artifact from './Artifact';
import Footer from '../footer/Footer';
import LoadingSlider from '../loading/LoadingSlider';

import { fetchArtifacts } from '../../store/reducers/artifactSlice';
import { fetchArtists } from '../../store/reducers/artistSlice';
import { fetchArtifactArtist } from '../../store/reducers/artifactArtistSlice';

const Home = () => {
    const dispatch = useDispatch();

    const afterImage = require('./jpeg-optimizer_PXL_20230808_003617536-_1_.jpeg');
    const beforeImage = require('./before.jpeg');

    // State to manage the visibility of LoadingSlider
    const [showLoadingSlider, setShowLoadingSlider] = useState(false);

    // Constants
    const LOADING_SLIDER_KEY = 'loadingSliderLastShown';
    const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                await Promise.all([
                    dispatch(fetchArtifacts()),
                    dispatch(fetchArtists()),
                    dispatch(fetchArtifactArtist())
                ]);
            }
            catch (e) {
                console.error(e);
            }
        };
        asyncFetchData();
    }, [dispatch]);

    const { artifacts, artists, artifactArtist, isDataLoaded } = useSelector((state) => ({
        artifacts: state.artifact.artifacts,
        artists: state.artist.artists,
        artifactArtist: state.artifactArtist.artifactArtist,
        isDataLoaded: state.artifact.isInitialDataLoaded,
    }));

    useEffect(() => {
        const checkLoadingSlider = () => {
            const lastShown = localStorage.getItem(LOADING_SLIDER_KEY);
            const now = Date.now();

            if (!lastShown) {
                // If never shown before, show it and set the timestamp
                setShowLoadingSlider(true);
                localStorage.setItem(LOADING_SLIDER_KEY, now.toString());
            } else {
                const lastShownTime = parseInt(lastShown, 10);
                if (isNaN(lastShownTime)) {
                    // If stored value is invalid, treat as never shown
                    setShowLoadingSlider(true);
                    localStorage.setItem(LOADING_SLIDER_KEY, now.toString());
                } else if (now - lastShownTime > TEN_MINUTES) {
                    // If more than 5 minutes have passed, show it again
                    setShowLoadingSlider(true);
                    localStorage.setItem(LOADING_SLIDER_KEY, now.toString());
                } else {
                    // If within 5 minutes, do not show
                    setShowLoadingSlider(false);
                }
            }
        };

        checkLoadingSlider();
    }, []);

    return (
        <>
            {showLoadingSlider && <LoadingSlider />}
            <div className='home-container'>
                <div className="gallery-container">
                    {artifactArtist.length ? (artifacts.length ? (artifacts.map((artifact, index) => {
                        let filteredArtists = [];
                        let reference = artifactArtist[index];
                        reference.artistID.forEach((artistID) => {
                            const artist = artists.find((artist) => artist.artistID === artistID);
                            if (artist) {
                                filteredArtists.push(artist);
                            }
                        });
                        return <Artifact key={artifact.id || index} artifact={artifact} artists={filteredArtists} reference={reference} />
                    })) : null) : null}
                    <div className="artifact-container">
                        <div className='before-after-slider'>
                            <ReactCompareSlider
                                itemOne={<ReactCompareSliderImage src={afterImage} alt="After" />}
                                itemTwo={<ReactCompareSliderImage src={beforeImage} alt="Before" />}
                                position={1}
                            />
                        </div>
                        <div className="info-wrapper">
                            <div className="artifact-name">THE TRANSITION</div>
                            <div className="single-line-div" />
                            <div className="artifact-blurb">Many thanks to the dozens of artists, craftspeople, and contractors that transformed a gray cube into a true work of art!</div>
                            <div className="artifact-artist-wrapper">
                                <div className="artifact-artist-description">CREATED BY</div>
                                <div className="last-name">Everybody</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Home;
