import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch stories (public endpoint)
export const fetchStory = createAsyncThunk(
  "story/fetchStory",
  async () => {
    try {
      const { data } = await axios.get("https://octopus-house.herokuapp.com/api/story");
      return data;
    } catch (e) {
      console.error(e);
      // Return fallback value on error
      return [];
    }
  }
);

// Update a story (protected endpoint)
export const updateStory = createAsyncThunk(
  "story/updateStory",
  async ({ id, title, content, token: passedToken }, thunkAPI) => {
    // Try to get token from Redux state, then localStorage, then use passed token.
    let token =
      (thunkAPI.getState().auth && thunkAPI.getState().auth.token) ||
      localStorage.getItem("token") ||
      passedToken;
    console.log("updateStory thunk: token", token);
    if (!token) {
      return thunkAPI.rejectWithValue("User not authenticated");
    }
    try {
      const { data } = await axios.patch(
        `https://octopus-house.herokuapp.com/api/story/${id}`,
        { title, content },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return data;
    } catch (e) {
      console.error("updateStory thunk error", e);
      return thunkAPI.rejectWithValue(e.response?.data || e.message);
    }
  }
);

const storySlice = createSlice({
  name: "storyData",
  initialState: {
    story: [],
    status: "idle",
    error: null,
    filter: null,
  },
  reducers: {
    getStoryList: (state, action) => {
      state.story = action.payload;
    },
    setErrorMsg: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetching stories
      .addCase(fetchStory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.story = action.payload;
      })
      .addCase(fetchStory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle updating a story
      .addCase(updateStory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStory.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedStory = action.payload;
        const index = state.story.findIndex(
          (story) => story.postID === updatedStory.postID
        );
        if (index !== -1) {
          // Update only the changed properties in place to preserve the array order.
          Object.assign(state.story[index], {
            title: updatedStory.title,
            content: updatedStory.content,
          });
        }
      })
      .addCase(updateStory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export const { getStoryList, setErrorMsg } = storySlice.actions;
export const storySliceReducer = storySlice.reducer;
export default storySlice;
