import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const FinalPost = () => {
    const afterImage = require('./jpeg-optimizer_PXL_20230808_003617536-_1_.jpeg');
    const beforeImage = require('./before.jpeg');

    return (
        <div className='before-after-slider'>
            <h2 className="image-date">Do we need a date?</h2>
            <h1 className="image-title">Final post title</h1>
            <ReactCompareSlider
                itemOne={<ReactCompareSliderImage src={afterImage} alt="After" />}
                itemTwo={<ReactCompareSliderImage src={beforeImage} alt="Before" />}
                position={1}
            />
            <div className="image-content">This is where the content for the final post will go</div>
        </div>
    )    
};

export default FinalPost;