import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Helper function to retrieve token
const getToken = (getState) => {
  // You may store the token in localStorage, or in another slice (like auth).
  return localStorage.getItem("token");
};

// 1) Fetch all artists
export const fetchArtists = createAsyncThunk(
  "artists/fetchArtists",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        "https://octopus-house.herokuapp.com/api/artists"
      );
      return data; // Contains the full list of artists from DB
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

// 2) Fetch a single artist and associated artifacts
export const fetchArtistData = createAsyncThunk(
  "artists/fetchArtistData",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `https://octopus-house.herokuapp.com/api/artists/${id}`
      );
      return data; // Contains the single artist from DB
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

// 3) Create artist
export const createArtist = createAsyncThunk(
  "artists/createArtist",
  async (newArtist, { getState, rejectWithValue }) => {
    try {
      const token = getToken(getState);
      if (!token) {
        return rejectWithValue("No token found. User not logged in.");
      }
      const { data } = await axios.post(
        "https://octopus-house.herokuapp.com/api/artists",
        newArtist,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // data here is the newly created artist from the DB
      return data;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

// 4) Update artist
export const updateArtist = createAsyncThunk(
  "artists/updateArtist",
  async ({ id, updatedInfo }, { getState, rejectWithValue }) => {
    try {
      const token = getToken(getState);
      if (!token) {
        return rejectWithValue("No token found. User not logged in.");
      }
      const { data } = await axios.put(
        `https://octopus-house.herokuapp.com/api/artists/${id}`,
        updatedInfo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // data is the updated artist object from the DB
      return data;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

// 5) Delete artist
export const deleteArtist = createAsyncThunk(
  "artists/deleteArtist",
  async (id, { getState, rejectWithValue }) => {
    try {
      const token = getToken(getState);
      if (!token) {
        return rejectWithValue("No token found. User not logged in.");
      }
      await axios.delete(`https://octopus-house.herokuapp.com/api/artists/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Return the ID so we know which one to remove from store
      return id;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

const artistSlice = createSlice({
  name: "artists",
  initialState: {
    artists: [],
    artistData: null,
    status: "idle",
    errorMsg: null,
  },
  reducers: {
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // -- FETCH ALL ARTISTS --
      .addCase(fetchArtists.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchArtists.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.artists = action.payload;
      })
      .addCase(fetchArtists.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      })

      // -- FETCH SINGLE ARTIST --
      .addCase(fetchArtistData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchArtistData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.artistData = action.payload;
      })
      .addCase(fetchArtistData.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      })

      // -- CREATE ARTIST --
      .addCase(createArtist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createArtist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.artists.push(action.payload);
      })
      .addCase(createArtist.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      })

      // -- UPDATE ARTIST --
      .addCase(updateArtist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateArtist.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedArtist = action.payload;
        const idx = state.artists.findIndex((a) => a.id === updatedArtist.id);
        if (idx !== -1) {
          state.artists[idx] = updatedArtist;
        }
      })
      .addCase(updateArtist.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      })

      // -- DELETE ARTIST --
      .addCase(deleteArtist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteArtist.fulfilled, (state, action) => {
        state.status = "succeeded";
        // action.payload is the ID of the deleted artist
        const deletedId = action.payload;
        state.artists = state.artists.filter((a) => a.id !== deletedId);
      })
      .addCase(deleteArtist.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.payload;
      });
  },
});

export const artistSliceReducer = artistSlice.reducer;
export const { setErrorMsg } = artistSlice.actions;
export default artistSlice;