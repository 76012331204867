import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { throttle } from 'lodash';
import '../../css/story.css';
import InnerStory from "./InnerStory";
import { fetchStory } from '../../store/reducers/storySlice';

const Story = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                await Promise.all([dispatch(fetchStory())]);
            } catch (e) {
                console.error(e);
            }
        };
        asyncFetchData();
    }, [dispatch]);

    const { story } = useSelector((state) => ({
        story: state.story.story,
    }));

    return (
        <div className="story-container">
            <InnerStory data={{ story }} />
        </div>
    );
};

export default Story;
