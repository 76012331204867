import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

axios.defaults.withCredentials = true;

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true);
    }
  }, []);

const login = async (username, password) => {
    try {
        setError(null);
        const response = await axios.post('https://octopus-house.herokuapp.com/api/login', { username, password });

        const { token } = response.data;
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
        // Decode token to get expiration time
        const decoded = jwtDecode(token);
        const expiresIn = (decoded.exp * 1000) - Date.now(); // Convert to milliseconds

        // Auto logout when token expires
        setTimeout(() => {
            logout();
        }, expiresIn);

        setIsAuthenticated(true);
        return true;
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    setError('Invalid username or password. Please try again.');
                    break;
                case 404:
                    setError('Login service not found. Please try again later.');
                    break;
                default:
                    setError(`An error occurred during login: ${error.response.data.message || 'Please try again.'}`);
            }
        } else if (error.request) {
            setError('No response received from the server. Please check your internet connection.');
        } else {
            setError('An unexpected error occurred. Please try again.');
        }
        console.error('Login error:', error);
        return false;
    }
};

  
let logoutTimer;

const logout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
    
    if (logoutTimer) clearTimeout(logoutTimer); // Clear any existing logout timers
    window.location.reload(); // Ensure the state is fully reset
};


  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};